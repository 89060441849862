<app-loading *ngIf="!profile"></app-loading>
<section class="content-user" *ngIf="profile">
  <header *ngIf="isLoggedIn && showEdit">
    <a routerLink="/setting">Edit</a>
  </header>
  <div class="header-card">
    <div class="banner" style="--bg-image: url({{profile?.banner?.url}})">
      <span class="profile-photo">
        <div class="figure" style="--bg-image: url({{profile?.photo?.url ? profile?.photo?.url : defaultDp}})">
        </div>
      </span>
    </div>
    <div class="profile-info">
      <span class="name">
        {{profile.name}}
      </span>
      <span class="designation">{{profile?.bio?.title}}</span>
      <div class="company-location-education">
        <span class="company">{{profile?.bio?.work}}</span>
        <span class="location">{{profile?.bio?.location}}</span>
        <span class="education">{{profile?.bio?.education}}</span>
      </div>
      <div class="skills">
        <span class="skill" *ngFor="let skill of profile?.bio?.skill?.split(',')">{{skill}}</span>
        <!-- <span class="skill">StartUp</span>
        <span class="skill">Coding</span> -->
      </div>
    </div>
  </div>

  <div class="save-contact">
    <button class="save-contact-button" (click)="generateVCardOnTheFly()">
      Save Contact
    </button>
    <button class="download-contact-button" (click)="showQR()">
      <i class="fa-solid fa-qrcode"></i>
    </button>
  </div>

  <div class="links">
    <div class="link" *ngFor="let link of linksList" (click)="followLink(link.linkType, link.link)">
      <span class="img">
        <i class="{{link.icon}}"></i>
      </span>
      <div class="link-content">
        <span class="title"> {{link.title}} </span>
        <span class="value"> {{link.link}} </span>
      </div>
      <span class="arrow"><i class="fa-solid fa-arrow-right"></i></span>
    </div>
  </div>

  <section class="footer" *ngIf="!isLoggedIn">
    <!-- <a routerLink="/shop">Get your hi card</a> -->
    &nbsp;&nbsp;&nbsp;
    <a routerLink="/login">Login to your hi card</a>
  </section>
</section>