<section class="content-login">
  <section class="login-box" [formGroup]="signupFG">
    <div class="title">
      Setup Your hiCard
    </div>
    <mat-form-field class="input-field" appearance="outline">
      <mat-label class="thelabel">Username</mat-label>
      <input type="text" matInput formControlName="username" (keypress)="alphaNumeric($event)">
    </mat-form-field>
    <mat-form-field class="input-field" appearance="outline">
      <mat-label class="thelabel">Password</mat-label>
      <input type="password" matInput formControlName="password">
    </mat-form-field>
    <mat-form-field class="input-field" appearance="outline">
      <mat-label class="thelabel">Re Enter Password</mat-label>
      <input type="password" matInput formControlName="repassword">
    </mat-form-field>
    <div style="width: 100%;" *ngIf="isLoading">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <br>
    <button class="login-button" (click)="signup()">
      Go
    </button>
    <b class="error-message">{{error}}</b>
  </section>
</section>