import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cancellation-and-refund-policies',
  templateUrl: './cancellation-and-refund-policies.component.html',
  styleUrls: ['./cancellation-and-refund-policies.component.scss', '../brand-style.scss']
})
export class CancellationAndRefundPoliciesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
