<!-- <section style="width: 100vw; height: 100vh; background-color: white;">

</section> -->
<section class="container" >
  <section class="mmenu">
    <div class="links">
      <a href="/brand/about-us">
        About Us
      </a>
      <a href="/brand/contact-us">
        Contact Us
      </a>
      <a href="https://www.facebook.com/hicard.in">
        <i class="fa-brands fa-facebook"></i>
      </a>
      <a href="https://www.instagram.com/hicard.in/">
        <i class="fa-brands fa-instagram"></i>
      </a>
      <a href="https://www.linkedin.com/company/hicard-in">
        <i class="fa-brands fa-linkedin"></i>
      </a>
    </div>
  </section>
  <section class="header">
    <div class="h-content">
      <img src="/assets/logo-old.webp" alt="Hi Card" height="50">
      <span class="text">Simply Share Your Business Info</span>
      <a href="https://github.com/hicard-in" target="_blank" style="text-decoration: none; color: black;">
        <i class="fa-brands fa-github" style="font-size: 40px;"></i>
      </a>
    </div>
  </section>
  <section class="content">
    <section class="banner">
    </section>
    <a routerLink="/shop">

      <div class="headline">
        <i class="fa-solid fa-sign-in" style="color: white;"></i> Sign Up
      </div>
    </a>
    <br>
    <br>
    <div class="section-title nodisplay">
      Features
    </div>
    <section class="features nodisplay">
      <div class="feature-card card">
        <span class="icon">
          <i class="fa-brands fa-android" style="color: #a4c639"></i>
          &nbsp;
          <i class="fa-brands fa-apple" style="color: lightgrey"></i>
        </span>
        <span class="text">
          Works with all types of phones. <br>
          Both iPhone and Android.
        </span>
      </div>

      <div class="feature-card card">
        <span class="icon">
          <i class="fa-solid fa-globe" style="color: lightblue;"></i>
        </span>
        <span class="text">
          No app required to share your profile. It opens in the browser.
        </span>
      </div>

      <div class="feature-card card">
        <span class="icon">
          <i class="fa-solid fa-infinity" style="color: lightgreen;"></i>
        </span>
        <span class="text">
          Built to last a lifetime. Update your info as it changes.
        </span>
      </div>
    </section>
    <br>
    <div class="section-title nodisplay">
      How it works
    </div>
    <section class="how-it-works nodisplay">
      <div class="how-it-works-card card">
        <span class="corner">1</span>
        <span class="icon">
          <i class="fa-solid fa-laptop" style="color: grey"></i>
          <i class="fa-solid fa-plus" style="color: lightgreen;"></i>
        </span>
        <span class="text">
          Company add employee profile on hiCard Portal
        </span>
      </div>

      <div class="how-it-works-card card">
        <span class="corner">2</span>
        <span class="icon">
          <i class="fa-solid fa-paper-plane" style="color: #2795eb;"></i>
          &nbsp;
          <i class="fa-solid fa-address-card" style="color: #2795eb;"></i>
        </span>
        <span class="text">
          We deliver the hiCard to the employee
        </span>
      </div>

      <div class="how-it-works-card card">
        <span class="corner">3</span>
        <span class="icon">
          <i class="fa-solid fa-mobile" style="color: grey;"></i>
        </span>
        <span class="text">
          Employee setup their hiCard profile
        </span>
      </div>

      <div class="how-it-works-card card">
        <span class="corner">4</span>
        <span class="icon">
          <i class="fa-solid fa-share-from-square" style="color: lightblue;"></i>
          <!-- <i class="fa-solid fa-network-wired" style="color: lightblue;"></i> -->
        </span>
        <span class="text">
          Ready To Network, within and outside your company network.
        </span>
      </div>
    </section>
    <div class="section-title">
      Create Your HiCard Profile today
    </div>
    <section class="sample-profile">
      <img src="/assets/sample-profile.webp" alt="Sample hiCard icard profile" width="400" routerLink="/johndoe">
      <img src="/assets/sample-profile-qr.png" alt="Sample hiCard icard" width="345" routerLink="/johndoe">
    </section>
    <div class="section-title nodisplay">
      Book a demo
    </div>
    <section class="book-demo nodisplay">
      <a href="https://calendly.com/hicard/demo" style="text-decoration: none;">

        <div class="how-it-works-card card">
          <span class="icon">
            <i class="fa-solid fa-calendar" style="color: lightgreen;"></i>
          </span>
          <span class="text">
            Book a demo
          </span>
        </div>
      </a>
    </section>
  </section>
</section>